import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
// import Blogavatar from "../components/blogavatar";
import {MDXRenderer} from 'gatsby-plugin-mdx';



// const createTagList = (tags) =>{
  
//     const myClassNames = 'tag tag-blog';
//       const listItems = tags.map((tag) =>
//         <li key={tag.toString()} className={myClassNames}>
          
//           {tag}
//         </li>
//       );
//       return (
//         <ul className="blog-tags">{listItems}</ul>
//       );
//   }




const PostPage = ({
    // data: {
    //     gcms: { post },
    // },
    data: { post }

}) => (
    <Layout>
        <Seo title={post.title} />
        
        <div className="blog-wrapper">
            
            <div className="blog-post">
            {/* <div className="post-detail" dangerouslySetInnerHTML={{__html: post.content.markdownNode.childMdx.body}}>

            </div> */}
            <div className="post-detail2">
             {/* {post.content.markdown}  */}
            {/* <MDXRenderer>{post.content.markdownNode.childMdx.body ? post.content.markdownNode.childMdx.body : post.content.markdown }</MDXRenderer> */}
              </div>
        
                {/* <div className="post-detail" dangerouslySetInnerHTML={{__html: post.content.html}}></div> */}
                {/* <span className="blog-review-date small-print">Last reviewed on: {post.date} */}
                 {/* {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          weekday: "short"
        }).format(post.date)} */}
  
        {/* </span> */}
            </div>
            {/* <div className="blog-author">
            {post.author.name}
            <img width="100%" src={post.author.picture.url} alt={post.author.name}></img>
            
            </div> */}
            <div className="blog-author">
            {/* <Blogavatar></Blogavatar> */}
            </div>
            <div className="blog-aside">
            {/* {createTagList(post.tags)} */}
                {/* perma link - {post.slug} */}
            </div>

           
        
        </div>

       
        
    </Layout>
);

//this line is it!

// import React from 'react';
// import { graphql } from 'gatsby';

// const ProductPage = ({ data: { product } }) => (
//   <React.Fragment>
//     <h1>{product.name}</h1>
//     <p>{product.description}</p>
//     <p>
//       {new Intl.NumberFormat('de-DE', {
//         style: 'currency',
//         currency: 'EUR',
//       }).format(product.price)}
//     </p>
//   </React.Fragment>
// );

// export const pageQuery = graphql`
//   query ProductPageQuery($id: String!) {
//     product: graphCmsProduct(id: { eq: $id }) {
//       name
//       description
//       price
//     }
//   }
// `;

// THIS IS THE NEXT AND PREVIOUS 
// allGraphCmsPost {
//   edges {
//     node {
//       title
//       slug
//     }
//     next {
//       title
//       id
//       slug
//     }
//     previous {
//       title
//       slug
//       id
//     }
//   }
// }



//CANT RUN 2 PAGE QUERIES!!
// export const pageQuery2 = graphql`
// query PostPageQuery2{ allGraphCmsPost {
//     edges {
//       node {
//         title
//         slug
//       }
//       next {
//         title
//         id
//         slug
//       }
//       previous {
//         title
//         slug
//         id
//       }
//     }
//   }
// }
// `


export const pageQuery = graphql`
  query PostPageQuery($id: String!) {
    post: graphCmsPost(id: { eq: $id }) {
      title
      slug
      content{
        markdown
        markdownNode {
          childMdx {
            body
          }
        }
      }
      
    }
  }
`;



// export const pageQuery = graphql`
//     query PostPageQuery($id: ID!) {
//       allGraphCmsPost {
//         nodes {
//           id
//           title
//           slug
//           excerpt
//           tags
//           content {
//             markdownNode {
//               childMdx {
//                 body
//               }
//             }
//           }
//           author {
//             name
//             picture {
//               authorAvatar {
//                 picture {
//                   fileName
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
// `

// export const pageQuery = graphql`
//     query PostPageQuery($id: ID!) {
//         gcms {
//             post(where: {id: $id}) {
//                 title
//                 tags
//                 slug
//                 date
//                 excerpt
//                 content{
//                     html
//                 }
//                 author {
//                     name
//                     picture {
//                         url(transformation: {image: {}})
//                         fileName
//                     }
//                 }
                
//             }
//         }
//     }
// `

export default PostPage;